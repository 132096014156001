/* ======= VARIABLES ======= */

$fa-font-path: 'fonts';
$enable-rounded: false;
$font-size-base: 1rem;
$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 3.5),
	7: ($spacer * 4),
	8: ($spacer * 5),
	9: ($spacer * 6) 
	), $spacers
);
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-color: #043B5D;
$primary: #007AC7;
$secondary: #FCDD00;
$white: #FFF;
$green: #9CBF1B;
$orange: #F29544;
$theme-colors: (
	"white": $white,
	"green": $green,
	"orange": $orange
);
$headings-font-weight: 700;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.5;
$display1-size: 3rem;
$display1-weight: 400;
$btn-font-weight: 600;
$btn-border-width: 2px;
$link-decoration: underline;
$navbar-light-toggler-border-color: transparent;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#007AC7' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-color: $headings-color;
$navbar-light-hover-color: rgba($headings-color, .7);
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($headings-color, .3);
$navbar-nav-link-padding-x: 1.25rem;
$hr-border-color: $primary;
$hr-border-width: 3px;



/* ======= IMPORT BOOTSTRAP, FONTS & OTHERS ======= */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/variables';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';



/* ======= HEADER ======= */

header#header {
	background-color: #fff;
	.navbar-brand {
		> img {
			transition: $transition-base;
			height: 5rem;
			@include media-breakpoint-up(lg) {
				height: 7rem;
			}
		}
	}
	&.shrink {
		.navbar-brand {
			> img {
				height: 2.5rem;
				@include media-breakpoint-up(lg) {
					height: 3.5rem;
				}
			}
		}
		.cat-button {
			padding: .25rem 0 !important;
			@include media-breakpoint-up(lg) {
				padding: .25rem 1rem !important;
			}
		}
	}
	.nav-item {
		display: flex;
		align-items: center;
		a {
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 700;
			transition: $transition-base;
			&:after {
				content: "";
				width: 1rem;
				height: 2px;
				background-color: transparent;
				display: block;
				margin: .25rem auto 0 auto;
			}
			&:hover:after {
				background-color: rgba(4,59,93,.7);
			}
			&.active:after {
				background-color: $primary;
			}
		}
		&.cat-button {
			transition: $transition-base;
			padding: .5rem 0;
			@include media-breakpoint-up(lg) {
				background-color: $secondary;
				padding: 1rem;
			}
			&:hover {
				background-color: darken($secondary, 7.5%);
			}
			a {
				color: $headings-color;
				display: flex;
				align-items: center;
				&:after {
					background-color: transparent;
				}
			}
		}
	}
}



/* ======= LAYOUT ======= */

.divider {
	width: 3rem;
	height: 2px;
	margin: 1rem 0;
	background: #FFF;
	border-bottom: 1px solid #FFF;
	&-large {
		width: 12rem;
	}
}



/* ======= UTILS ======= */

.bg-cover {
	background-position: center center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	overflow: hidden !important;
	&#catalogue {
		background-image: linear-gradient(rgba(40,59,66, .7), rgba(40,59,66, .7)), url('/assets/img/bg-catalogo.jpg');
	}
	&#about-intro {
		background-image: linear-gradient(rgba(40,59,66, .7), rgba(40,59,66, .7)), url('/assets/img/header-chisiamo.jpg');
	}
	&#cookies-intro {
		background-image: linear-gradient(rgba(40,59,66, .7), rgba(40,59,66, .7)), url('/assets/img/header-contatti.jpg');
	}
	&#privacy-intro {
		background-image: linear-gradient(rgba(40,59,66, .7), rgba(40,59,66, .7)), url('/assets/img/header-contatti.jpg');
	}
	&#thankyou-intro {
		background-image: linear-gradient(rgba(40,59,66, .7), rgba(40,59,66, .7)), url('/assets/img/header-contatti.jpg');
	}
	&#contatti-intro {
		background-image: linear-gradient(rgba(40,59,66, .7), rgba(40,59,66, .7)), url('/assets/img/header-contatti.jpg');
	}
	&#contatti-form {
		background-image: url('/assets/img/bg-form.jpg');
	}
}



/* ======= TYPO ======= */

@include media-breakpoint-up(md) {
	html {
		font-size: 1.1rem;
	}
}

h2,
.h2,
h3,
.h3 {
	font-weight: 600;
}

h3 {
	color: $primary;
}


.media, a > i {
	opacity: 1;
	transition: $transition-fade;
	&:hover {
		opacity: .75;
	}
	a {
		text-decoration: none;
	}
}


/* ======= BTN ======= */

.btn {
	text-transform: uppercase;
	text-decoration: none;
}



/* ======= CAROUSEL ======= */

#homeCarousel {
	.carousel-item {
		@extend .bg-cover;
		height: 30rem;
		@include media-breakpoint-up(lg) {
			height: 35rem;
		}
		&:after {
			background-image: none;
		}
		.carousel-caption {
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
			@include media-breakpoint-up(lg) {
				text-align: left;
			}
		}
	}
}

/* ======= FOOTER ======= */

#footer-top,
#footer-bottom {
	.footer-widget {
		color: #FFF;
		margin-bottom: 3rem;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
		h6 {
			color: #FFF;
			text-transform: uppercase;
		}
		a {
			color: #FFF;
		}
	}
}

#footer-top {
	background-color: #283B42;
}

#footer-bottom {
	background-color: #19262A;
	color: #FFF;
	a {
		text-decoration: underline;
		color: #FFF;
		&:hover {
			text-decoration: none;
			color: rgb(217, 217, 217);
		}
	}
}



/* ======= FORM ======= */

// form {
// 	label {
// 		color: #FFF;
// 		font-weight: 600;
// 		&.is-invalid {
// 			color: theme-color("danger");
// 		}
// 	}
// 	textarea, input, select {
// 		&.form-control {
// 			color: $headings-color;
// 			&:focus,
// 			&.focus	{
// 				color: $primary;
// 				border-color: #62ABD9;
// 			}
// 		}
// 	}
// 	a {
// 		color: $white;
// 		&:hover {
// 			color: darken($white, 7.5%);
// 		}
// 	}
// 	.error.help-block {
// 		color: theme-color("danger");
// 	}
// }

/* ======= FORM ======= */

$form_bg: transparent;

$form_div_width: 50%;

$form_label_width: 100%;
$form_label_color: #FFF;
$form_label_fontsize: 100%;

$form_input_color: $headings-color;
$form_input_bg: #fff;
$form_input_border: 1px solid #62ABD9;
$form_input_width: 100%;
$form_input_padding: 1% 2%;
$form_input_fontsize: 110%;

$form_input_color_focus: $primary;
$form_input_border_focus: 1px solid $primary;
$form_input_bg_focus: #fff;

$form_privacy_fontsize: 80%; 

$form_invia_bg: #FCDD00;
$form_invia_bg_hover: #d6bb00;
$form_invia_color: #043B5D;
$form_invia_width: auto;
$form_invia_height: auto;
$form_invia_border: 1px solid #FFF;
$form_invia_fontsize: 120%;

$form_error_color: yellow;


#nomesia_form {
	padding: 20px;
	margin: 10px 0;
	clear: both;
	background: $form_bg;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	input[type="hidden"] {
		display: none !important;
	}
	div {
		padding: .25rem 1rem;
		margin: 10px 0px;
		float: left;
		width: $form_div_width;
		&#submit_container{
			float: none;
			clear: both;
			margin: 0 auto;
		}
		select {
			width: $form_input_width;
			*width:130px;
		}
	}
	label {
		float: left;
		color: $form_label_color;
		font-size: $form_label_fontsize;
		font-weight: 600;
		display: block;
		width: $form_label_width;
		*width: 100px; /* IE7 vuole necessariamente un valore in pixel */
		&.error {
			display: block;
			width: 90%!important;
			font-size: 80% !important;
			padding: 2px 5px;
			color: $form_error_color;
			background: none;
			border: none;
		}
	}
	textarea, input, select {
		color: $form_input_color;
		background: $form_input_bg;
		border: $form_input_border;
		font-size: $form_input_fontsize;
		padding: $form_input_padding;
		width: $form_input_width;
		*width: 100px;
		float: left;
		display:inline-block;
		-webkit-transition: border linear .2s, box-shadow linear .2s;
		-moz-transition: border linear .2s, box-shadow linear .2s;
		-o-transition: border linear .2s, box-shadow linear .2s;
		transition: border linear .2s, box-shadow linear .2s;
		&:focus	{
			color: $form_input_color_focus;
			border: $form_input_border_focus;
			background: $form_input_bg_focus;
			outline: 0;
			-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			-moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
		}
	}
	#messaggio_container {
		width: $form_input_width;
		textarea {
			height: 6rem;
		}
	}
	#privacy_container, #marketing_container {
		border: none;
		float: left;
		padding: 0 0 5px;
		width: 100%;
		label{
			float: left;
			font-weight: normal;
			display: block;
			width: 80%;
			font-size: $form_privacy_fontsize;
			line-height: 1.2em;
			clear: none;
		}
		input {
			margin: 5px 20px 0 0;
			padding: 0;
			background: none;
			border: none;
			width: 15px;
			float: left;
		}
		a {
			color: #FFF;
			&:hover {
				color: rgb(217, 217, 217);
			}
		}
	}
	#more_info_container {
		width: $form_label_width;
		label {
			width: 100%;
		}
		a {
			color: #FFF;
			&:hover {
				color: rgb(217, 217, 217);
			}
		}
	}
	.button_form {
		background: $form_invia_bg;
		color: $form_invia_color;
		font-weight: bold;
		text-transform: uppercase;
		border: $form_invia_border;
		width: $form_invia_width;
		display: block;
		margin: 15px auto;
		padding: 8px 20px;
		height: $form_invia_height;
		font-size: $form_invia_fontsize;
		float: none;
		cursor: pointer;
		transition: $transition-base;
		&:hover, &:focus {
			background: $form_invia_bg_hover;
			color: $form_invia_color;
		}
	}
	.checked {
		display: none!important;
	}
	.field_status{
		margin: 0;
		padding: 0;
		&.error{
			display: block;
		}
	}
	span.ast {
		color: #FFF;
	}
}

@media \0screen {
	#nomesia_form textarea:focus, #nomesia_form input:focus, #nomesia_form select:focus{outline: thin dotted;}
}

@include media-breakpoint-down(xs) {

	#nomesia_form label {width: 100% !important;}
	#nomesia_form input {width: 100% !important;}
	#nomesia_form select {width: 100% !important;}
	#nomesia_form textarea {width: 100% !important;}
	#nomesia_form #privacy_container input, #nomesia_form #marketing_container input {width: 5% !important; margin: 0 4% 0 0;}
	#nomesia_form #privacy_container label, #nomesia_form #marketing_container label{width: 91% !important; padding: 0;}
	#nomesia_form .button_form {white-space: normal;}
}

@include media-breakpoint-down(md) {

	#nomesia_form div {width: 100% !important;}
}


/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	background: $secondary;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	#top-arrow {
		-o-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-top: 5px solid $headings-color;
		border-left: 5px solid $headings-color;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}